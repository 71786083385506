import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

document.addEventListener('DOMContentLoaded',function() {
    window.addEventListener('resize', function() {
        // Auto scale element
        document.querySelectorAll('.auto-scale').forEach(e => {
            var parent = e.parentNode;

            var cs = getComputedStyle(parent);

            var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
            var paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);
            // var borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
            // var borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

            var scale = Math.min(
                (parent.clientWidth - paddingX) / e.offsetWidth,
                (parent.offsetHeight - paddingY) / e.offsetHeight
            );

            if (scale > 1) {
                e.classList.add('origin-center');
                e.classList.remove('origin-top-left');
                parent.style.height = `${e.offsetHeight * 1.1}px`;
                scale = 1;
            } else {
                e.classList.add('origin-top-left');
                e.classList.remove('origin-center');
                parent.style.height = `${e.offsetHeight * scale * 1.1}px`;
            }

            e.style.transform = `scale(${scale})`;
        });
    });
    window.dispatchEvent(new CustomEvent('resize'));

    // Scroll event
    var returnTopBtn = document.querySelector('.return-page-top');

    window.addEventListener('scroll', function(){
        // Return to top button
        var showAfter = 100;
        if ( this.scrollY > showAfter ) {
            returnTopBtn.classList.add('opacity-100', 'rotate-0');
        } else {
            returnTopBtn.classList.remove('opacity-100', 'rotate-0');
        }
    });
    window.dispatchEvent(new CustomEvent('scroll'));

    // Return page top
    returnTopBtn.addEventListener('click', function(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        history.replaceState(null, null, ' ');
    });

    document.querySelectorAll('.expander').forEach(function (expander) {
        expander.addEventListener('click', function () {
            var target = document.querySelector(expander.dataset.expander);
            var className = expander.dataset.expanderToggle;

            if (!className) {
                className = 'h-0';
            }
            if (target.classList.contains(className)) {
                target.style.height = `${target.scrollHeight}px`;
            } else {
                target.style.height = null;
            }
            target.classList.toggle(className);

            var gradient = target.querySelector('.gradient');
            if (gradient) {
                gradient.classList.toggle('hidden');
            }
            var chevron = expander.querySelector('svg');
            if (chevron && expander.dataset.expanderFlip !== undefined) {
                chevron.classList.toggle('rotate-180');
            }

            var text = expander.dataset.expanderText;
            if (text !== undefined) {
                var innerText = expander.querySelector('.inner-text');
                var currentText = innerText.innerHTML;
                innerText.innerHTML = text;
                expander.dataset.expanderText = currentText;
            }
        });
    });
});